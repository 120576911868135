import React from "react";
import Topbar from "../components/Topbar";
import {
  Typography,
  Container,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  })
);

const NotFound: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Topbar />
      <div className={classes.root}>
        <Container>
          <Typography>Not found</Typography>
        </Container>
      </div>
    </>
  );
};

export default NotFound;
