export type Ok<T> = { ok: T };
export type Err<E> = { err: E };
export type Result<T, E> = Ok<T> | Err<E>;

export const Ok = <T>(ok: T): Ok<T> => ({ ok });
export const Err = <E>(err: E): Err<E> => ({ err });

export const isOk = <T>(result: Result<T, any>): result is Ok<T> =>
  "ok" in result;
export const isErr = <E>(result: Result<any, E>): result is Err<E> =>
  "err" in result;
