import React, { ComponentType } from "react";
import showFile from "../utils/showFile";
import { ProcessParams } from "../pdf/process";
import renderPdf from "../utils/renderPdf";
import prepareFilename from "../utils/prepareFilename";

export interface ProvidedProps {
  loading: boolean;
  onDownload: () => void;
}

interface Props<P extends ProvidedProps> {
  processParams?: ProcessParams;
  component: ComponentType<Omit<P, keyof ProvidedProps> & ProvidedProps>;
  componentProps: Omit<P, keyof ProvidedProps>;
}

interface PdfFile {
  data: Uint8Array;
  filename: string;
}

interface State {
  pdfFile: PdfFile | null;
}

class RenderPdf<P extends ProvidedProps> extends React.PureComponent<
  Props<P>,
  State
> {
  state: State = {
    pdfFile: null,
  };

  componentDidMount() {
    this.updatePdf();
  }

  componentDidUpdate(prevProps: Props<P>) {
    if (prevProps === this.props) return;
    this.updatePdf();
  }

  updatePdf() {
    const { processParams } = this.props;
    if (!processParams) {
      this.setState({ pdfFile: null });
      return;
    }

    renderPdf(processParams).then((data) => {
      const filename = prepareFilename(processParams.fillParams.date);
      this.setState({
        pdfFile: {
          data,
          filename,
        },
      });
    });
  }

  render() {
    const { pdfFile } = this.state;
    const loading = pdfFile === null;
    const { component: Component, componentProps } = this.props;
    return (
      <Component
        loading={loading}
        onDownload={this.download}
        {...componentProps}
      />
    );
  }

  download = () => {
    const { pdfFile } = this.state;
    if (!pdfFile) throw new Error("No pdf file at download");

    showFile({
      blob: pdfFile.data,
      filename: pdfFile.filename,
      mimetype: "application/pdf",
    });
  };
}

export default RenderPdf;
