import arrayBufferToImage from "./arrayBufferToImage";
import blobToArrayBuffer from "./blobToArrayBuffer";

export type ConvertableArrayBufferImage = Exclude<
  CanvasImageSource,
  SVGElement
>;

export interface ConvertArrayBufferImageParams {
  buf: ArrayBuffer;
  fromMimeType: string;
  toMimeType: string;
}

const convertArrayBufferImage = async (
  params: ConvertArrayBufferImageParams
): Promise<ArrayBuffer> => {
  const { buf, fromMimeType, toMimeType } = params;
  const image = await arrayBufferToImage({
    buf,
    mimetype: fromMimeType,
  });

  const { width, height } = image;
  const canvas = new OffscreenCanvas(width, height);
  const ctx = canvas.getContext("2d");
  if (!ctx) throw new Error("Unable to obtain 2d context");
  ctx.drawImage(image, 0, 0);

  const blob = await canvas.convertToBlob({ type: toMimeType });
  return blobToArrayBuffer({ blob });
};

export default convertArrayBufferImage;
