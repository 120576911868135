import TokenReponse from "../TokenResponse";
import FetchFn from "./FetchFn";
import OpenParams from "./OpenParams";

interface GetTokenParams extends OpenParams {
  client_id: string;
  grant_type: string;
  code: string;
  code_verifier: string;
  redirect_uri: string;
}

const getToken = (fetch: FetchFn, url: string, params: GetTokenParams) => {
  return fetch(url, {
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json" },
    method: "POST",
  }).then((res) => res.json() as Promise<TokenReponse>);
};

export default getToken;
