import React, { useState, useMemo, useDebugValue, useEffect } from "react";
import FlowManager from "./authorizationCodePKCE/FlowManager";
import TokenResponse from "./authorizationCodePKCE/TokenResponse";
import OAuth2Client from "./authorizationCodePKCE/OAuth2Client";
import { readAndClearVerifier, storeVerifier } from "./verifierStorage";
import AuthRoutes from "./AuthRoutes";
import { useHistory } from "react-router-dom";
import { loadAuthData, saveAuthData } from "./authDataStorage";

const oauth2Client = new OAuth2Client({
  issuer: "https://upwork-cos-signer.eu.auth0.com",
  clientId: "nHM2pNjKFqEfcRfm0rRNfmflRNmx2OkA",
  audience: "https://upwork-cos-web",
  fetch: fetch.bind(window),
});

export interface AuthData {
  validUntil: Date;
  accessToken: string;
}

const Auth: React.FC = (props) => {
  const { children } = props;

  const flowManager = useMemo(
    () =>
      new FlowManager({
        oauth2Client,
        readAndClearVerifier,
        storeVerifier,
      }),
    []
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  useDebugValue(isLoading);

  const [authData, setAuthData] = useState<AuthData | undefined>();
  useDebugValue(authData);

  useEffect(() => {
    // Attempt loading the stored auth data value to avoid re-authentication.
    const loadPreviousAuth = async () => {
      const loadedAuthData = await loadAuthData();
      if (loadedAuthData) {
        setAuthData(loadedAuthData);
      }
      setIsLoading(false);
    };

    loadPreviousAuth();
  }, []);

  const history = useHistory();
  const handleTokenResponse = (tokenResponse: TokenResponse) => {
    const validUntil = new Date(Date.now() + tokenResponse.expires_in * 1000);
    const newAuthData = {
      validUntil,
      accessToken: tokenResponse.access_token,
    };

    // Save auth data for later.
    saveAuthData(newAuthData);

    // Update current state to instantly switch to allow for an instant switch
    // to the authenticated state.
    setAuthData(newAuthData);

    // Navigate to the root page.
    history.push("/");
  };

  const isAuthValid = useMemo(() => {
    if (!authData) {
      // No auth data.
      return false;
    }
    if (authData.validUntil.getTime() < Date.now()) {
      // Expired auth data.
      return false;
    }
    return true;
  }, [authData]);

  if (isLoading) {
    return null;
  }

  return isAuthValid ? (
    <>{children}</>
  ) : (
    <AuthRoutes
      flowManager={flowManager}
      onTokenResponse={handleTokenResponse}
    />
  );
};

export default Auth;
