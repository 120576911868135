import { DependencyList, useEffect, useState } from "react";

const useAsyncMemo = <T>(
  factory: () => Promise<T>,
  deps: DependencyList | undefined
): T | undefined => {
  const [state, setState] = useState<T | undefined>();

  useEffect(() => {
    factory().then((value) => {
      setState(value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return state;
};

export default useAsyncMemo;
