import localforage, { persist } from "../localforage";
import { AuthData } from "./Auth";

const authDataKey = "oauth2_auth_data";

export const saveAuthData = async (authData: AuthData): Promise<void> => {
  await persist();
  await localforage.setItem(authDataKey, authData);
};

export const loadAuthData = async (): Promise<AuthData | undefined> =>
  await localforage.getItem<AuthData | undefined>(authDataKey);
