import React from "react";
import ReactDOM from "react-dom";
import "./global";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const root = document.getElementById("root");
if (root === null) {
  throw new Error("Couldn't find root");
}

ReactDOM.unstable_createRoot(root).render(<App />);

serviceWorker.register({});
