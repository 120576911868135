import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";

export type CurrencyTextFieldProps = TextFieldProps;

const CurrencyTextField: React.FC<CurrencyTextFieldProps> = React.forwardRef(
  (props, ref) => {
    const { inputProps, InputProps } = props;
    return (
      <TextField
        type="number"
        {...props}
        ref={ref}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          ...InputProps,
        }}
        inputProps={{ inputmode: "decimal", ...inputProps }}
      />
    );
  }
);

export default CurrencyTextField;
