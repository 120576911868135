import React, { useMemo } from "react";
import Topbar from "../components/Topbar";
import {
  Typography,
  Container,
  makeStyles,
  createStyles,
  Theme,
  CircularProgress,
  Link,
} from "@material-ui/core";
import RenderPdf from "../components/RenderPdf";
import { useLocation } from "react-router-dom";
import { load } from "../settings";
import useAsyncMemo from "../hooks/useAsyncMemo";
import { ProcessParams } from "../pdf/process";
import formatDate from "../pdf/fill/formatDate";
import formatAmount from "../pdf/fill/formatAmount";
import DownloadButton from "../components/DownloadButton";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
    preview: {
      marginTop: theme.spacing(1),
    },
    downloadButton: {
      marginTop: theme.spacing(1),
    },
    backLink: {
      marginTop: theme.spacing(1),
    },
  })
);

export interface RenderLocationStateParams {
  amount: number;
  date: Date;
}

const Render: React.FC = () => {
  const classes = useStyles();

  const location = useLocation<RenderLocationStateParams | undefined>();
  const currentParams = location.state;
  if (!currentParams)
    throw new Error(
      "No params passed, use sign page to prepare and pass the params"
    );
  const { amount, date } = currentParams;

  const settings = useAsyncMemo(load, []);
  const processParams = useMemo<ProcessParams | undefined>(() => {
    if (!settings) return;
    return {
      fillParams: {
        amount,
        date,
        name: settings.name,
      },
      addSignaturesParams: settings.signatureImage
        ? {
            imageBuf: settings.signatureImage,
            x: settings.signatureShift.x,
            y: settings.signatureShift.y,
            scale: settings.signatureScale,
          }
        : undefined,
    };
  }, [amount, date, settings]);

  return (
    <>
      <Topbar />
      <div className={classes.root}>
        <Container>
          {settings !== undefined && processParams !== undefined ? (
            <>
              <Typography variant="h5">Signing for</Typography>

              <div className={classes.preview}>
                <Typography>Name: {settings.name}</Typography>
                <Typography>Amount: {formatAmount(amount)} USD</Typography>
                <Typography>Date: {formatDate(date)}</Typography>
              </div>

              <RenderPdf
                processParams={processParams}
                component={DownloadButton}
                componentProps={{
                  className: classes.downloadButton,
                }}
              />

              <div className={classes.backLink}>
                <Link component={RouterLink} to="/">
                  Sign another one
                </Link>
              </div>
            </>
          ) : (
            <CircularProgress />
          )}
        </Container>
      </div>
    </>
  );
};

export default Render;
