import React from "react";
import { Switch, Route } from "react-router-dom";
import Sign from "./pages/Sign";
import Settings from "./pages/Settings";
import Render from "./pages/Render";
import Demo from "./pages/Demo";
import NotFound from "./pages/NotFound";

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={Sign} />
    <Route path="/render" component={Render} />
    <Route path="/settings" component={Settings} />
    <Route path="/demo" component={Demo} />
    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;
