import { createMuiTheme } from "@material-ui/core";

// Allow extra options for theme here (if needed).
declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {}
  interface ThemeOptions {}
}

const theme = createMuiTheme({});

export default theme;
