import IOpenParams from "./OpenParams";

const buildQuery = <T extends IOpenParams>(url: string, params: T) => {
  const u = new URL(url);
  Object.keys(params).forEach((param) => {
    u.searchParams.set(param, params[param]);
  });
  return u.toString();
};

export default buildQuery;
