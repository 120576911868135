import { Spec, State } from "./types";

export interface AddSignaturesParams {
  imageBuf: ArrayBuffer;
  x: number;
  y: number;
  scale: number;
}

const addSignatures = async (
  state: State,
  spec: Spec,
  params: AddSignaturesParams
): Promise<void> => {
  const { doc } = state;
  const { docLayout } = spec;
  const { imageBuf, x, y, scale } = params;

  const {
    base: { shift: baseShift, scale: baseScale },
    pages: pageLayouts,
  } = docLayout;

  const image = await doc.embedPng(imageBuf);

  const pages = doc.getPages();
  if (pages.length !== pageLayouts.length) {
    throw new Error("Invalid pages amount, must be using the wrong template");
  }

  const width = image.width * scale * baseScale;
  const height = image.height * scale * baseScale;

  const px = baseShift.x + x * baseScale;
  const py = baseShift.y - height - y * baseScale;

  pageLayouts.forEach(({ shift }, pageIndex) => {
    const { x, y } = shift;
    pages[pageIndex].drawImage(image, {
      x: px + x,
      y: py + y,
      width: width,
      height: height,
    });
  });
};

export default addSignatures;
