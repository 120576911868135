const addCommas = (s: string): string => {
  const list: string[] = [];
  let prevPos = s.length;
  let pos = prevPos - 6;
  list.push(s.substring(pos, prevPos));
  while (pos > 0) {
    prevPos = pos;
    pos = pos - 3;
    list.push(s.substring(pos, prevPos));
  }
  return list.reverse().join(",");
};

const formatAmount = (amount: number): string => addCommas(amount.toFixed(2));

export default formatAmount;
