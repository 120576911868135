import React, { useState } from "react";
import Topbar from "../components/Topbar";
import { makeStyles, Theme, createStyles, Container } from "@material-ui/core";
import SignatureInput from "../components/SignatureInput";
import demoSignatureUrl from "../assets/signature-sample.png";
import useFetchedArrayBuffer from "../hooks/useFetchedArrayBuffer";
import { Shift } from "../utils/overlayImageInput/Engine";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(2),
    },
  })
);

const Demo: React.FC = () => {
  const classes = useStyles();

  const [signatureImage, setSignatureImage] = useFetchedArrayBuffer(
    demoSignatureUrl
  );
  const [shift, setShift] = useState<Shift>({ x: 0, y: 0 });
  const [scale, setScale] = useState<number>(1);

  const stats = { shift, scale, signatureImage };

  return (
    <>
      <Topbar />
      <div className={classes.root}>
        <Container>
          <div>
            <SignatureInput
              image={signatureImage}
              initialShift={shift}
              initialScale={scale}
              onImageChange={setSignatureImage}
              onShiftChange={setShift}
              onScaleChange={setScale}
            />
          </div>
          <div>
            <DrawStats {...stats} />
          </div>
        </Container>
      </div>
    </>
  );
};

export default Demo;

const DrawStats: React.FC<{
  signatureImage: ArrayBuffer | null;
  shift: Shift;
  scale: number;
}> = ({ signatureImage, shift, scale }) => (
  <div>
    <div>{JSON.stringify(signatureImage)}</div>
    <div>{JSON.stringify(shift)}</div>
    <div>{JSON.stringify(scale)}</div>
  </div>
);
