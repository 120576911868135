import { Shift, Scale } from "./utils/overlayImageInput/Engine";
import fetchArrayBuffer from "./utils/fetchArrayBuffer";
import sampleSignature from "./assets/signature-sample.png";
import localforage, { persist } from "./localforage";

export interface Settings {
  name: string;

  signatureImage: ArrayBuffer | null;
  signatureShift: Shift;
  signatureScale: Scale;
}

export const save = async (settings: Settings): Promise<void> => {
  await persist();
  await localforage.setItem("settings", settings);
};

export const load = async (): Promise<Settings> => {
  const settings = await localforage.getItem<Settings>("settings");
  if (settings) return settings;

  const image = await fetchArrayBuffer(sampleSignature);

  return {
    name: "Alan Turing",
    signatureImage: image,
    signatureShift: { x: 0, y: 0 },
    signatureScale: 0.3235335449737089,
  };
};
