import { Result, isOk, isErr } from "./result";

export interface ValidationErrorState {
  highlightInput: boolean;
}

export type ValidationState<T extends { [K in keyof T]: T[K] }> = {
  [K in keyof T]: Result<T[K], ValidationErrorState>;
};

export const validationStatePayload = <T extends object>(
  v: ValidationState<T>
): T | undefined => {
  const payload: Partial<T> = {};
  for (const keyStr in v) {
    const key: keyof T = keyStr;
    const elem = v[key];
    if (!isOk(elem)) {
      return undefined;
    }
    payload[key] = elem.ok;
  }
  return payload as T;
};

export const shouldHighlightErrorInput = <T extends object>(
  v: ValidationState<T>,
  key: keyof ValidationState<T>
): boolean => {
  const elem = v[key];
  if (!isErr<ValidationErrorState>(elem)) {
    return false;
  }
  return elem.err.highlightInput;
};
