import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";

export interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
}

// TODO: add loading indicator.

const LoadingButton: React.FC<LoadingButtonProps> = React.forwardRef(
  (props, ref) => {
    const { loading, ...rest } = props;
    return <Button {...rest} ref={ref} />;
  }
);

export default LoadingButton;
