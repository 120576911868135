const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const isProduction = process.env.NODE_ENV === "production";
const isServiceWorkerSupported = "serviceWorker" in navigator;
const publicUrl = process.env.PUBLIC_URL;
const swUrl = `${publicUrl}/service-worker.js`;

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export const register = (config: Config): void => {
  if (!isServiceWorkerSupported || !isProduction) {
    return;
  }

  const onLoad = async () => {
    if (isLocalhost) {
      checkValidServiceWorker(swUrl, config);

      await navigator.serviceWorker.ready;
      console.log("Service worker is ready.");
      return;
    }

    // Is not localhost. Just register service worker
    registerValidSW(swUrl, config);
  };

  window.addEventListener("load", onLoad);
};

const registerValidSW = async (
  swUrl: string,
  config: Config
): Promise<void> => {
  const registration = await navigator.serviceWorker.register(swUrl);
  registration.onupdatefound = () => {
    const installingWorker = registration.installing;
    if (installingWorker === null) {
      return;
    }
    installingWorker.onstatechange = () => {
      if (installingWorker.state !== "installed") {
        return;
      }
      if (navigator.serviceWorker.controller) {
        // At this point, the updated precached content has been fetched,
        // but the previous service worker will still serve the older
        // content until all client tabs are closed.
        console.log(
          "New content is available and will be used when all " +
            "tabs for this app are closed."
        );

        // Execute callback.
        const { onUpdate } = config;
        if (onUpdate) {
          onUpdate(registration);
        }
      } else {
        // At this point, everything has been precached.
        // It's the perfect time to display a
        // "Content is cached for offline use." message.
        console.log("Content is cached for offline use.");

        // Execute callback.
        const { onSuccess } = config;
        if (onSuccess) {
          onSuccess(registration);
        }
      }
    };
  };
};

const checkValidServiceWorker = async (
  swUrl: string,
  config: Config
): Promise<void> => {
  // Check if the service worker can be fetched.
  let response: Response;
  try {
    response = await fetch(swUrl);
  } catch {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
    return;
  }

  // Ensure service worker exists, and that we really are getting a JS file.
  const contentType = response.headers.get("content-type");
  if (
    response.status === 404 ||
    (contentType != null && contentType.indexOf("javascript") === -1)
  ) {
    // No service worker found, probably a different app.
    // Unregsiter Service Worker and reload the page.
    const registration = await navigator.serviceWorker.ready;
    await registration.unregister();
    window.location.reload();
    return;
  }

  // Service worker found. Proceed as normal.
  await registerValidSW(swUrl, config);
};

export const unregister = async (): Promise<void> => {
  if (!isServiceWorkerSupported) {
    return;
  }
  const registration = await navigator.serviceWorker.ready;
  await registration.unregister();
};
