import authorizationUrl from "./utils/authorizationUrl";
import FetchFn from "./utils/FetchFn";
import getToken from "./utils/getToken";
import OpenParams from "./utils/OpenParams";

interface OAuth2ClientConfig {
  // Issuer is the address of the token issuer to contact.
  issuer: string;

  // Client ID to be used at OAuth flows.
  clientId: string;

  // Audience to use in the OAuth flows.
  audience: string;

  // Fetch implementation to use.
  fetch: FetchFn;
}

interface AuthorizationUrlParam extends OpenParams {
  redirect_uri: string;
  code_challenge_method: string;
  code_challenge: string;
}

interface GetTokenParams extends OpenParams {
  code: string;
  code_verifier: string;
  redirect_uri: string;
}

class OAuth2Client {
  constructor(public readonly config: OAuth2ClientConfig) {}

  public authorizationUrl(params: AuthorizationUrlParam) {
    const { audience, issuer, clientId } = this.config;

    return authorizationUrl(`${issuer}/authorize`, {
      audience,
      client_id: clientId,
      response_type: "code",
      ...params,
    });
  }

  public getToken(params: GetTokenParams) {
    const { issuer, clientId, fetch } = this.config;

    return getToken(fetch, `${issuer}/oauth/token`, {
      client_id: clientId,
      grant_type: "authorization_code",
      ...params,
    });
  }
}

export default OAuth2Client;
