import OAuth2Client from "./OAuth2Client";
import generateVerifier from "./utils/generateVerifier";
import OpenParams from "./utils/OpenParams";
import makeChallenge from "./utils/makeChallenge";

interface Props {
  oauth2Client: OAuth2Client;

  storeVerifier(verifier: string): Promise<void>;
  readAndClearVerifier(): Promise<string | undefined>;
}

interface AuthorizationUrlParams extends OpenParams {
  redirect_uri: string;
}

interface GetTokenParams extends OpenParams {
  code: string;
  redirect_uri: string;
}

class FlowManager {
  constructor(public readonly props: Props) {}

  public async authorizationUrl(params: AuthorizationUrlParams) {
    const { oauth2Client } = this.props;
    const verifier = await generateVerifier();
    await this.props.storeVerifier(verifier);

    const { code_challenge_method, code_challenge } = await makeChallenge(
      verifier
    );

    const url = oauth2Client.authorizationUrl({
      code_challenge,
      code_challenge_method,
      ...params,
    });

    return url;
  }

  public async getToken(params: GetTokenParams) {
    const { oauth2Client } = this.props;
    const verifier = await this.props.readAndClearVerifier();

    if (!verifier) {
      throw new Error("Empty code verifier");
    }

    const tokenResponse = await oauth2Client.getToken({
      code: params.code,
      code_verifier: verifier,
      ...params,
    });

    return tokenResponse;
  }
}

export default FlowManager;
