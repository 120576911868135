import {
  acroFormEnableAppearences,
  fillAcroTextField,
  getAcroFieldName,
  getAcroFields,
  getAcroForm,
} from "pdf-lib-utils";
import formatAmount from "./fill/formatAmount";
import formatDate from "./fill/formatDate";
import { Spec, State } from "./types";

export interface FillParams {
  name: string;
  date: Date;
  amount: number;
}

export const fill = (state: State, _spec: Spec, params: FillParams): void => {
  const { doc } = state;

  const acroForm = getAcroForm(doc);
  if (!acroForm) throw new Error("Missing AcroForm");

  acroFormEnableAppearences(acroForm);

  const acroFields = getAcroFields(acroForm);
  if (!acroFields) throw new Error("Missing AcroFields");

  const values: Record<string, string> = {
    Name: params.name,
    Date: formatDate(params.date),
    Amount: formatAmount(params.amount),
  };

  acroFields.forEach((acroField) => {
    const name = getAcroFieldName(acroField);
    const text = name && values[name];
    fillAcroTextField(acroField, {
      text,
      flags: {
        readonly: true,
        multiline: false,
      },
    });
  });
};

export default fill;
