import { loadDoc, loadDocLayout } from "../pdf/load";
import { process as processPdf, ProcessParams } from "../pdf/process";

const renderPdf = async (params: ProcessParams) => {
  const [doc, docLayout] = await Promise.all([loadDoc(), loadDocLayout()]);
  const state = { doc };
  const spec = { docLayout };
  await processPdf(state, spec, params);
  return doc.save();
};

export default renderPdf;
