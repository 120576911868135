import React from "react";
import { Switch, Route } from "react-router-dom";
import OAuth2Init from "./OAuth2Init";
import OAuth2Callback from "./OAuth2Callback";
import NotFound from "../pages/NotFound";
import FlowManager from "./authorizationCodePKCE/FlowManager";
import TokenReponse from "./authorizationCodePKCE/TokenResponse";

export const callbackPath = "/oauth/callback";

// eslint-disable-next-line no-restricted-globals
export const callbackUrl = () => location.origin + callbackPath;

interface Props {
  flowManager: FlowManager;
  onTokenResponse: (tokenResponse: TokenReponse) => void;
}

const AuthRoutes: React.FC<Props> = ({ flowManager, onTokenResponse }) => (
  <Switch>
    <Route
      exact
      path="/"
      render={() => <OAuth2Init flowManager={flowManager} />}
    />
    <Route
      path={callbackPath}
      render={() => (
        <OAuth2Callback
          flowManager={flowManager}
          onTokenResponse={onTokenResponse}
        />
      )}
    />
    <Route path="*" component={NotFound} />
  </Switch>
);

export default AuthRoutes;
