import localforage from "localforage";

localforage.config({
  driver: localforage.INDEXEDDB,
  name: "upwork-cos",
  version: 1.0,
  // Should be alphanumeric, with underscores.
  storeName: "upwork_cos",
});

export default localforage;

export const persist = async () => {
  if (!navigator || !navigator.storage) return;
  await navigator.storage.persist();
};
