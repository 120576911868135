type TouchPosition = { x: number; y: number };
type TouchPositionsDistance = number;

enum Interaction {
  MOVE = "move",
  SCALE = "scale",
}

type MoveState = TouchPosition;
type ScaleState = TouchPositionsDistance;

type TouchState =
  | { k: Interaction.MOVE; v: MoveState }
  | { k: Interaction.SCALE; v: ScaleState };

const getTouchPosition = (touch: Touch): TouchPosition => ({
  // Use clientX and clientY values as the position.
  x: touch.clientX,
  y: touch.clientY,
});

const getTouchPositionsDistance = (
  a: TouchPosition,
  b: TouchPosition
): TouchPositionsDistance =>
  Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));

const processTouchEvent = (ev: TouchEvent): TouchState | undefined => {
  // If there's not a valid amount of touches the state is undefined.
  const len = ev.touches.length;
  if (len < 1 || len > 2) {
    return;
  }

  const touch = ev.touches.item(0);
  if (touch === null) {
    throw new Error("Unexpected null touch");
  }

  const touchPosition = getTouchPosition(touch);

  if (len === 1) {
    return { k: Interaction.MOVE, v: touchPosition };
  }

  const secondTouch = ev.touches.item(1);
  if (secondTouch === null) {
    throw new Error("Unexpected null second touch");
  }

  const secondTouchPosition = getTouchPosition(secondTouch);

  const distance = getTouchPositionsDistance(
    touchPosition,
    secondTouchPosition
  );

  return { k: Interaction.SCALE, v: distance };
};

export default class TouchEventHandler {
  lastState: TouchState | undefined;

  constructor(
    public readonly onMove: (dx: number, dy: number) => void,
    public readonly onScale: (ds: number) => void
  ) {}

  public register = (elem: HTMLElement) => {
    elem.addEventListener("touchstart", this.initPosition);
    elem.addEventListener("touchend", this.initPosition);
    elem.addEventListener("touchmove", this.handleTouchMove);
  };

  public unregister = (elem: HTMLElement) => {
    elem.removeEventListener("touchmove", this.handleTouchMove);
    elem.removeEventListener("touchend", this.initPosition);
    elem.removeEventListener("touchstart", this.initPosition);
  };

  initPosition = (ev: TouchEvent) => {
    ev.preventDefault();

    // Assign the state if there is any or reset it if there's none.
    this.lastState = processTouchEvent(ev);
  };

  handleTouchMove = (ev: TouchEvent) => {
    ev.preventDefault();

    const newState = processTouchEvent(ev);

    // If there's no new state or no last state the dalta is undefined,
    // so we don't generate the any events.
    if (!newState || !this.lastState) {
      return;
    }

    let scheduledEvent: (() => void) | undefined;

    if (
      newState.k === Interaction.MOVE &&
      this.lastState.k === Interaction.MOVE
    ) {
      // Compute deltas.
      const dx = newState.v.x - this.lastState.v.x;
      const dy = newState.v.y - this.lastState.v.y;
      // Schedule event.
      scheduledEvent = () => this.onMove(dx, dy);
    } else if (
      newState.k === Interaction.SCALE &&
      this.lastState.k === Interaction.SCALE
    ) {
      // Compute relation.
      const ds = newState.v / this.lastState.v;
      // Schedule event.
      scheduledEvent = () => this.onScale(ds);
    }

    // Preserve the state for subsequent events.
    this.lastState = newState;

    // Trigger the event if it was scheduled.
    if (scheduledEvent !== undefined) {
      scheduledEvent();
    }
  };
}
