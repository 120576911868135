import React, { useEffect } from "react";
import FlowManager from "./authorizationCodePKCE/FlowManager";
import { callbackUrl } from "./AuthRoutes";

interface Props {
  readonly flowManager: FlowManager;
}

const OAuth2Init: React.FC<Props> = (props) => {
  const { flowManager } = props;

  useEffect(() => {
    const startFlow = async () => {
      const url = await flowManager.authorizationUrl({
        redirect_uri: callbackUrl(),
      });
      navigate(url);
    };

    startFlow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default OAuth2Init;

const navigate = (url: string): void => {
  // eslint-disable-next-line no-restricted-globals
  location.href = url;
};
