import React, { useEffect } from "react";
import FlowManager from "./authorizationCodePKCE/FlowManager";
import { callbackUrl } from "./AuthRoutes";
import TokenReponse from "./authorizationCodePKCE/TokenResponse";

interface Props {
  readonly flowManager: FlowManager;
  readonly onTokenResponse: (tokenResponse: TokenReponse) => void;
}

const OAuth2Callback: React.FC<Props> = (props) => {
  const { flowManager, onTokenResponse } = props;

  useEffect(() => {
    const handleCallback = async () => {
      const code = getUrlParam("code");
      if (!code) {
        throw new Error("No code");
      }
      const tokenResponse = await flowManager.getToken({
        code,
        redirect_uri: callbackUrl(),
      });
      onTokenResponse(tokenResponse);
    };

    handleCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default OAuth2Callback;

const getUrlParam = (key: string): string | null => {
  // eslint-disable-next-line no-restricted-globals
  const url = new URL(location.href);
  return url.searchParams.get(key);
};
