import React, { useState, ChangeEvent, useMemo } from "react";
import Topbar from "../components/Topbar";
import {
  Container,
  TextField,
  Button,
  Grid,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { useHistory } from "react-router-dom";
import { RenderLocationStateParams } from "./Render";
import {
  validationStatePayload,
  ValidationState,
  shouldHighlightErrorInput,
  ValidationErrorState,
} from "../utils/validationState";
import { Result, Ok, Err } from "../utils/result";
import CurrencyTextField from "../components/CurrencyTextField";
import formatIsoDate from "../utils/formatIsoDate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(2),
    },
  })
);

const Sign: React.FC = () => {
  const classes = useStyles();

  const [amount, setAmount] = useState<string>("");
  const [date, setDate] = useState<string>(formatIsoDate(new Date()));

  const makeHandleChange = (setter: (val: string) => any) => (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setter(event.target.value);
  };

  const validationState = useMemo<ValidationState<RenderLocationStateParams>>(
    () => ({
      amount: validateNumber(amount),
      date: validateDate(date),
    }),
    [amount, date]
  );

  const payload = useMemo<RenderLocationStateParams | undefined>(
    () => validationStatePayload(validationState),
    [validationState]
  );

  const history = useHistory();

  const handleSign = () => {
    if (!payload) {
      return;
    }

    history.push("/render", payload);
  };

  const isSignDisabled = payload === undefined;
  return (
    <>
      <Topbar />
      <div className={classes.root}>
        <Container>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <CurrencyTextField
                  id="amount"
                  label="Amount"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  fullWidth
                  value={amount}
                  onChange={makeHandleChange(setAmount)}
                  error={shouldHighlightErrorInput(validationState, "amount")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="date"
                  label="Date"
                  type="date"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  fullWidth
                  value={date}
                  onChange={makeHandleChange(setDate)}
                  error={shouldHighlightErrorInput(validationState, "date")}
                />
              </Grid>
            </Grid>
            <div>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                size="large"
                startIcon={<CreateIcon />}
                onClick={handleSign}
                disabled={isSignDisabled}
              >
                Sign
              </Button>
            </div>
          </form>
        </Container>
      </div>
    </>
  );
};

export default Sign;

const validateNumber = (str: string): Result<number, ValidationErrorState> => {
  if (str === "") {
    return Err({ highlightInput: false });
  }
  let num: number;
  try {
    num = Number.parseFloat(str);
    if (Number.isNaN(num)) {
      throw new Error("Failed to parse numeric value");
    }
  } catch {
    return Err({ highlightInput: true });
  }
  return Ok(num);
};

const validateDate = (str: string): Result<Date, ValidationErrorState> => {
  if (str === "") {
    return Err({ highlightInput: false });
  }
  let date: Date;
  try {
    date = new Date(Date.parse(str));
  } catch {
    return Err({ highlightInput: true });
  }
  return Ok(date);
};
