import addSignatures, { AddSignaturesParams } from "./addSignatures";
import fill, { FillParams } from "./fill";
import { Spec, State } from "./types";

export interface ProcessParams {
  fillParams: FillParams;
  addSignaturesParams?: AddSignaturesParams;
}

export const process = async (
  state: State,
  spec: Spec,
  params: ProcessParams
): Promise<void> => {
  const { fillParams, addSignaturesParams } = params;
  fill(state, spec, fillParams);
  if (addSignaturesParams) {
    await addSignatures(state, spec, addSignaturesParams);
  }
};
