import React from "react";
import LoadingButton, { LoadingButtonProps } from "./LoadingButton";

export interface DownloadButtonProps extends LoadingButtonProps {
  onDownload: () => void;
}

const DownloadButton: React.FC<DownloadButtonProps> = (props) => {
  const { loading, onDownload, ...rest } = props;
  return (
    <>
      <LoadingButton
        color="primary"
        variant="outlined"
        disabled={loading}
        loading={loading}
        onClick={() => {
          onDownload();
        }}
        {...rest}
      >
        Download
      </LoadingButton>
    </>
  );
};
export default DownloadButton;
