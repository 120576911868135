import localforage from "../localforage";

const verifierKey = "oauth2_code_verifier";

export const storeVerifier = async (verifier: string): Promise<void> => {
  await localforage.setItem(verifierKey, verifier);
};

export const readAndClearVerifier = async (): Promise<string | undefined> => {
  const value = await localforage.getItem<string>(verifierKey);
  await localforage.removeItem(verifierKey);
  return value;
};
