import React, { useState, useEffect } from "react";
import OverlayImageInput from "./OverlayImageInput";
import { Shift, Scale } from "../utils/overlayImageInput/Engine";
import backgroundImageUrl from "../assets/signature-bg.png";
import fetchArrayBuffer from "../utils/fetchArrayBuffer";
import { Button, CircularProgress } from "@material-ui/core";
import pickFile from "../utils/pickFile";
import arrayBufferToImage from "../utils/arrayBufferToImage";
import convertArrayBufferImage from "../utils/convertArrayBufferImage";

interface Props
  extends Partial<React.ComponentPropsWithoutRef<typeof OverlayImageInput>> {
  readonly image: ArrayBuffer | null;
  readonly initialShift: Shift;
  readonly initialScale: Scale;
  onImageChange: (image: ArrayBuffer) => void;
  onShiftChange: (shift: Shift) => void;
  onScaleChange: (scale: Scale) => void;
}

const overlayMimeType = "image/png";

const backroundPivot: Shift = { x: 52, y: 102 };

const addPivot = (shift: Shift) => ({
  x: shift.x - backroundPivot.x,
  y: shift.y - backroundPivot.y,
});

const subtractPivot = (shift: Shift) => ({
  x: shift.x + backroundPivot.x,
  y: shift.y + backroundPivot.y,
});

const SignatureInput: React.FC<Props> = (props) => {
  const {
    image,
    initialShift,
    initialScale,
    onShiftChange,
    onScaleChange,
    onImageChange,
    ...rest
  } = props;

  const [
    backgroundImage,
    setBackgroundImage,
  ] = useState<HTMLImageElement | null>(null);

  useEffect(() => {
    const loadBackgroundImage = async (): Promise<void> => {
      const buf = await fetchArrayBuffer(backgroundImageUrl);
      const img = await arrayBufferToImage({ buf, mimetype: "image/png" });
      setBackgroundImage(img);
    };

    loadBackgroundImage();
    return () => {
      setBackgroundImage(null);
    };
  }, []);

  const [overlayImage, setOverlayImage] = useState<HTMLImageElement | null>(
    null
  );

  useEffect(() => {
    if (!image) {
      setOverlayImage(null);
    } else {
      const loadOverlayImage = async (
        buf: ArrayBuffer,
        mimetype: string
      ): Promise<void> => {
        const img = await arrayBufferToImage({ buf, mimetype });
        setOverlayImage(img);
      };

      loadOverlayImage(image, overlayMimeType);
    }

    return () => {
      setOverlayImage(null);
    };
  }, [image]);

  const pickNewImage = async () => {
    const { buf, mimeType } = await pickFile({ accept: [overlayMimeType] });
    if (mimeType === overlayMimeType) {
      onImageChange(buf);
      return;
    }

    const convertedBuf = await convertArrayBufferImage({
      buf,
      fromMimeType: mimeType,
      toMimeType: overlayMimeType,
    });

    onImageChange(convertedBuf);
  };

  const handleNewImageClick = () => {
    pickNewImage();
  };

  const handleShiftChange = (shift: Shift) => {
    onShiftChange(addPivot(shift));
  };

  return (
    <div>
      {image ? (
        <>
          <div>
            {backgroundImage && overlayImage ? (
              <OverlayImageInput
                {...rest}
                width={backgroundImage.naturalWidth}
                height={backgroundImage.naturalHeight}
                background={{ image: backgroundImage, x: 0, y: 0 }}
                overlay={{ image: overlayImage, x: 0, y: 0 }}
                initialShift={subtractPivot(initialShift)}
                initialScale={initialScale}
                onShiftChange={handleShiftChange}
                onScaleChange={onScaleChange}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
          <div>
            <Button onClick={handleNewImageClick}>Pick new image</Button>
          </div>
        </>
      ) : (
        <div>
          <Button onClick={handleNewImageClick}>Pick image</Button>
        </div>
      )}
    </div>
  );
};

export default SignatureInput;
