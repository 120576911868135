import buildQuery from "./buildQuery";
import IOpenParams from "./OpenParams";

export interface IAuthorizationUrlParams extends IOpenParams {
  audience: string;
  client_id: string;
  response_type: string;
  code_challenge: string;
  code_challenge_method: string;
  redirect_uri: string;
}

const authorizationUrl = (url: string, params: IAuthorizationUrlParams) =>
  buildQuery(url, params);

export default authorizationUrl;
