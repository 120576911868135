import TouchEventsHandler from "./TouchEventsHandler";

export type SupportedImageSource = Exclude<CanvasImageSource, SVGElement>;

export interface ImageSpec {
  image: SupportedImageSource;
  x: number;
  y: number;
}

export interface Shift {
  readonly x: number;
  readonly y: number;
}

export type Scale = number;

export interface EngineParams {
  readonly canvas: HTMLCanvasElement;

  readonly background: ImageSpec;
  readonly overlay: ImageSpec;

  readonly initialShift: Shift;
  readonly onShiftChange?: (shift: Shift) => void;

  readonly initialScale: Scale;
  readonly onScaleChange?: (scale: Scale) => void;
}

export default class Engine {
  render: CanvasRenderingContext2D;
  touchEventsHandler: TouchEventsHandler;

  public readonly canvas: HTMLCanvasElement;
  public readonly background: ImageSpec;
  public readonly overlay: ImageSpec;

  public shift: Shift;
  public onShiftChange?: (shift: Shift) => void;

  public scale: Scale;
  public onScaleChange?: (scale: Scale) => void;

  constructor(params: EngineParams) {
    const {
      canvas,
      background,
      overlay,
      initialShift,
      onShiftChange,
      initialScale,
      onScaleChange,
    } = params;
    this.canvas = canvas;
    this.background = background;
    this.overlay = overlay;
    this.shift = initialShift;
    this.onShiftChange = onShiftChange;
    this.scale = initialScale;
    this.onScaleChange = onScaleChange;

    this.render = mustGetContext(canvas);

    this.touchEventsHandler = new TouchEventsHandler(
      this.updateShift,
      this.updateScale
    );

    this.registerListeners();
  }

  public drop = () => {
    this.unregisterListeners();
  };

  public update = () => {
    // TODO: redraw only if needed
    this.redraw();
  };

  redraw = () => {
    // TODO: optimize redrawing via off-screen canvas or layered canvases.

    this.render.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.render.drawImage(
      this.background.image,
      this.background.x,
      this.background.y
    );

    this.render.drawImage(
      this.overlay.image,
      this.overlay.x + this.shift.x,
      this.overlay.y + this.shift.y,
      this.overlay.image.width * this.scale,
      this.overlay.image.height * this.scale
    );
  };

  updateShift = (dx: number, dy: number) => {
    const { x, y } = this.shift;
    this.shift = { x: x + dx, y: y + dy };

    this.redraw();

    if (this.onShiftChange) {
      this.onShiftChange(this.shift);
    }
  };

  updateScale = (ds: number) => {
    this.scale *= ds;

    this.redraw();

    if (this.onScaleChange) {
      this.onScaleChange(this.scale);
    }
  };

  handleMouseMove = (ev: MouseEvent) => {
    ev.preventDefault();

    if (ev.movementX === 0 && ev.movementY === 0) {
      // No movement occured, noop.
      return;
    }

    if ((ev.buttons & 1) !== 1) {
      // Mouse is not pressed, skip.
      return;
    }

    this.updateShift(ev.movementX, ev.movementY);
  };

  handleMouseWheel = (ev: WheelEvent) => {
    ev.preventDefault();

    // TODO: consider letting users adjust the scaling values.
    const ds = ev.deltaY < 0 ? 1.05 : 0.95;
    this.updateScale(ds);
  };

  registerListeners = () => {
    this.canvas.addEventListener("mousemove", this.handleMouseMove);
    this.canvas.addEventListener("wheel", this.handleMouseWheel);
    this.touchEventsHandler.register(this.canvas);
  };

  unregisterListeners = () => {
    this.touchEventsHandler.unregister(this.canvas);
    this.canvas.removeEventListener("wheel", this.handleMouseWheel);
    this.canvas.removeEventListener("mousemove", this.handleMouseMove);
  };
}

const mustGetContext = (canvas: HTMLCanvasElement) => {
  const render = canvas.getContext("2d");
  if (!render) {
    throw new Error("Unable to obtain 2d context for canvas");
  }
  return render;
};
