import React from "react";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Routes from "./Routes";
import Auth from "./auth/Auth";
import { BrowserRouter as Router } from "react-router-dom";

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Auth>
            <Routes />
          </Auth>
        </Router>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
