const fetchArrayBuffer = async (
  input: RequestInfo,
  init?: RequestInit
): Promise<ArrayBuffer> => {
  const res = await fetch(input, init);
  const { status } = res;
  if (status < 200 || status >= 400) {
    throw new Error(
      `Unable to fetch file, server responded with status ${status}`
    );
  }

  return await res.arrayBuffer();
};

export default fetchArrayBuffer;
