import blobToArrayBuffer from "./blobToArrayBuffer";

export interface PickFileParams {
  // A list of mime types to accept as input.
  accept?: string[];
}

export interface PickFileResult {
  buf: ArrayBuffer;
  mimeType: string;
}

type CleanupFn = () => void;

const pickFile = (params: PickFileParams): Promise<PickFileResult> =>
  new Promise((resolve, reject) => {
    const { accept } = params;
    const cleanupFns: CleanupFn[] = [];

    const doCleanup = () => {
      for (const cleanupFn of cleanupFns) {
        cleanupFn();
      }
    };

    const handleError = (err: any) => {
      doCleanup();
      reject(err);
    };

    const handleSuccess = (buf: ArrayBuffer, mimeType: string) => {
      doCleanup();
      resolve({ buf, mimeType });
    };

    const input = document.createElement("input");
    input.type = "file";
    if (accept) {
      input.accept = accept.join(", ");
    }

    const onInputChange = function (this: HTMLInputElement, event: Event) {
      const target = event.target as HTMLInputElement | undefined;
      if (!target) throw new Error("Expected currentTarget, was empty");
      if (target !== input)
        throw new Error("Expected currentTarget to match the input");

      const { files } = target;
      if (!files) throw new Error("Expected some files, got null");
      if (files.length !== 1)
        throw new Error("Expected a single file, got none or multiple");
      const file = files[0];

      blobToArrayBuffer({ blob: file }).then(
        (buf) => {
          handleSuccess(buf, file.type);
        },
        (err) => {
          handleError(err);
        }
      );
    };

    const onInputError = function (this: HTMLInputElement, event: ErrorEvent) {
      handleError(event.error);
    };

    input.addEventListener("change", onInputChange, { once: true });
    cleanupFns.push(() => {
      input.removeEventListener("change", onInputChange);
    });

    input.addEventListener("error", onInputError, { once: true });
    cleanupFns.push(() => {
      input.removeEventListener("error", onInputError);
    });

    input.click();
  });

export default pickFile;
