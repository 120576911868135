import React from "react";
import Engine, {
  Shift,
  ImageSpec,
  Scale,
} from "../utils/overlayImageInput/Engine";

type CanvasProps = JSX.IntrinsicElements["canvas"] & {
  width: number;
  height: number;
};

export interface Props extends CanvasProps {
  background: ImageSpec;
  overlay: ImageSpec;
  initialShift: Shift;
  initialScale: Scale;
  onShiftChange?: (shift: Shift) => void;
  onScaleChange?: (scale: Scale) => void;
}

class OverlayImageInput extends React.Component<Props> {
  canvasRef = React.createRef<HTMLCanvasElement>();
  engine: Engine | undefined;

  initEngine = (): void => {
    const canvas = this.canvasRef.current;
    if (!canvas) throw new Error("Expected canvas not found");

    const {
      background,
      overlay,
      initialShift,
      initialScale,
      onShiftChange,
      onScaleChange,
    } = this.props;

    const engine = new Engine({
      canvas,
      background,
      overlay,
      initialShift,
      initialScale,
      onShiftChange,
      onScaleChange,
    });

    engine.registerListeners();
    engine.redraw();

    this.engine = engine;
  };

  deinitEngine = (): void => {
    const { engine } = this;
    if (!engine) {
      return;
    }
    engine.unregisterListeners();
  };

  componentDidMount() {
    this.initEngine();
  }

  componentWillUnmount() {
    this.deinitEngine();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    this.deinitEngine();
    this.initEngine();
  }

  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    // Since the canvas element is the only thing this component renders,
    // we only need to re-render (update) this component if the props we pass
    // to the canvas chave changed. So, we compare the new and old props, and
    // return `true` is they're not equal.
    const newCanvasProps = filterCanvasProps(nextProps);
    const currentCanvasProps = filterCanvasProps(this.props);
    const propsAreEqual = canvasPropsEqual(currentCanvasProps, newCanvasProps);
    // If props are equal that means they didn't change, and the component
    // should not update.
    return !propsAreEqual;
  }

  render() {
    const { style, ...rest } = filterCanvasProps(this.props);
    const effectiveStyle: React.CSSProperties = {
      touchAction: "none",
      userSelect: "none",
      // Vendors
      msTouchAction: "none",
      MozUserSelect: "none",
      WebkitUserSelect: "none",
      msUserSelect: "none",
      ...style,
    };
    return <canvas style={effectiveStyle} {...rest} ref={this.canvasRef} />;
  }
}

export default OverlayImageInput;

const filterCanvasProps = (props: Props): CanvasProps => {
  const {
    background,
    overlay,
    initialShift,
    initialScale,
    onShiftChange,
    onScaleChange,
    ...other
  } = props;
  return other;
};

const canvasPropsEqual = (a: CanvasProps, b: CanvasProps) => {
  const keys = new Set([
    ...((Object.keys(a) as any) as (keyof CanvasProps)[]),
    ...((Object.keys(b) as any) as (keyof CanvasProps)[]),
  ]);
  keys.forEach((key) => {
    if (a[key] !== b[key]) {
      console.log(
        `Canvas props do not match for key ${key}: a => ${a[key]}, b => ${b[key]}`
      );
      return false;
    }
  });
  return true;
};
