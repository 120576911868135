import { PDFDocument } from "pdf-lib";
import { DocLayout } from "./types";

import templateLayout from "../assets/template-layout.json";
import template from "../assets/template.pdf";

export const loadData = async () => {
  const res = await fetch(template);
  const isSuccess = res.status >= 200 && res.status < 400;
  if (!isSuccess) {
    throw new Error("Failed to download template");
  }
  return res.arrayBuffer();
};

export const loadDoc = async (): Promise<PDFDocument> => {
  const data = await loadData();
  return PDFDocument.load(data);
};

export const loadDocLayout = async (): Promise<DocLayout> => templateLayout;
