import React from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { Link, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    settingsButton: {
      marginLeft: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

const Topbar: React.FC = (props) => {
  const classes = useStyles();
  const routeMatch = useRouteMatch("/settings");

  const isSettings = routeMatch !== null;

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {isSettings ? "Settings" : "Sign"}
          </Typography>
          <IconButton
            className={classes.settingsButton}
            color="inherit"
            aria-label="settings"
            component={Link}
            to={isSettings ? "/" : "/settings"}
          >
            <SettingsIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Topbar;
