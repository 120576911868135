import { useState, useEffect, Dispatch, SetStateAction } from "react";
import fetchArrayBuffer from "../utils/fetchArrayBuffer";

export type ImageState = ArrayBuffer | null;

const useFetchedArrayBuffer = (
  url: string
): [ImageState, Dispatch<SetStateAction<ImageState>>] => {
  const [buf, setBuf] = useState<ImageState>(null);

  useEffect(() => {
    fetchArrayBuffer(url).then((file) => {
      setBuf(file);
    });
  }, [url]);

  return [buf, setBuf];
};

export default useFetchedArrayBuffer;
