export interface ArrayBufferToImageParams {
  buf: ArrayBuffer;
  mimetype: string;
}

const arrayBufferToImage = (
  params: ArrayBufferToImageParams
): Promise<HTMLImageElement> => {
  const unsignedView = new Uint8Array(params.buf, 0);
  const newBlob = new Blob([unsignedView], { type: params.mimetype });
  const imageUrl = window.URL.createObjectURL(newBlob);
  const img = document.createElement("img");

  return new Promise((resolve, reject) => {
    img.addEventListener(
      "load",
      () => {
        resolve(img);
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the
          // ObjectURL.
          window.URL.revokeObjectURL(imageUrl);
        }, 100);
      },
      { once: true }
    );

    img.addEventListener(
      "error",
      (ev: ErrorEvent) => {
        reject(ev.error);
      },
      { once: true }
    );

    img.src = imageUrl;
  });
};

export default arrayBufferToImage;
