export interface BlobToArrayBufferParams {
  blob: Blob;
}

type CleanupFn = () => void;

const blobToArrayBuffer = (
  params: BlobToArrayBufferParams
): Promise<ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const { blob } = params;
    const cleanupFns: CleanupFn[] = [];

    const doCleanup = () => {
      for (const cleanupFn of cleanupFns) {
        cleanupFn();
      }
    };

    const handleError = (err: any) => {
      doCleanup();
      reject(err);
    };

    const handleSuccess = (buf: ArrayBuffer) => {
      doCleanup();
      resolve(buf);
    };

    const onFileReaderLoadEnd = function (
      this: FileReader,
      event: ProgressEvent<FileReader>
    ) {
      const target = event.target;
      if (!target)
        throw new Error("File Reader's Error Event's Current Target is empty");

      const { error, result } = target;
      if (error) {
        handleError(error);
        return;
      }

      // Since there's no error, we know the `result` is an `ArrayBuffer`.
      handleSuccess(result as ArrayBuffer);
    };

    const reader = new FileReader();

    reader.addEventListener("loadend", onFileReaderLoadEnd, { once: true });
    cleanupFns.push(() => {
      reader.removeEventListener("loadend", onFileReaderLoadEnd);
    });

    reader.readAsArrayBuffer(blob);
  });

export default blobToArrayBuffer;
