import React, { useState, useMemo, ChangeEvent, useEffect } from "react";
import Topbar from "../components/Topbar";
import {
  makeStyles,
  Theme,
  createStyles,
  Container,
  TextField,
  Button,
  Grid,
  Box,
  CircularProgress,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import SignatureInput from "../components/SignatureInput";
import { Shift, Scale } from "../utils/overlayImageInput/Engine";
import { save, load } from "../settings";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
    signatureInput: {
      borderStyle: "dashed",
      borderWidth: "thin",
      borderColor: theme.palette.divider,
    },
    button: {
      marginTop: theme.spacing(2),
    },
  })
);

const Settings: React.FC = () => {
  const classes = useStyles();

  const [isReady, setIsReady] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [signatureImage, setSignatureImage] = useState<ArrayBuffer | null>(
    null
  );
  const [shift, setShift] = useState<Shift>({ x: 0, y: 0 });
  const [scale, setScale] = useState<Scale>(1);

  const signatureInput = useMemo(
    () => () => (
      <Box margin={1}>
        {isReady ? (
          <SignatureInput
            className={classes.signatureInput}
            image={signatureImage}
            initialShift={shift}
            initialScale={scale}
            onImageChange={setSignatureImage}
            onShiftChange={setShift}
            onScaleChange={setScale}
          />
        ) : (
          <CircularProgress />
        )}
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signatureImage, isReady]
  );

  const history = useHistory();

  const handleNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(event.target.value);
  };

  const handleSave = () => {
    save({
      name,
      signatureImage,
      signatureScale: scale,
      signatureShift: shift,
    }).then(() => {
      history.push("/");
    });
  };

  useEffect(() => {
    const loadState = async () => {
      const settings = await load();
      setName(settings.name);
      setSignatureImage(settings.signatureImage);
      setShift(settings.signatureShift);
      setScale(settings.signatureScale);

      setIsReady(true);
    };
    loadState();
  }, []);

  return (
    <>
      <Topbar />
      <div className={classes.root}>
        <Container>
          {isReady ? (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={name}
                    onChange={handleNameChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="signature"
                    label="Signature"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: signatureInput,
                    }}
                  />
                </Grid>
              </Grid>
              <div>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  size="large"
                  startIcon={<SaveIcon />}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </>
          ) : (
            <CircularProgress />
          )}
        </Container>
      </div>
    </>
  );
};

export default Settings;
