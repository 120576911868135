import base64URLEncode from "./base64URLEncode";
import { sha256 } from "./crypto";

interface Challenge {
  code_challenge: string;
  code_challenge_method: string;
}

const makeChallenge = async (verifier: string): Promise<Challenge> => {
  const buffer = new Buffer(verifier, "utf8");
  const codeChallenge = await base64URLEncode(
    new Uint8Array(await sha256(buffer))
  );
  return {
    code_challenge: codeChallenge,
    code_challenge_method: "S256",
  };
};

export default makeChallenge;
